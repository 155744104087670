/*
 *
 */
import * as React from 'react';
import Page from '../components/page';

/*
 *
 */
const PageContent = () => {
	return (
		<Page
			title="Mentions légales"
			content={(
				<React.Fragment>
					<h2>Éditeur</h2>
					<p>L’éditeur responsable du site isabelle-kopp.be est:</p>
					<ul>
						<li>Isabelle KOPP</li>
						<li>Siège social: Rue de la chapelle 47, 1430 Rebecq - Belgique</li>
						<li>Numéro d'entreprise: 0744.619.213</li>
					</ul>

					<h2>Vie privée</h2>
					<h3>Données personnelles</h3>
					<p>
						Les données à caractère personnel qui sont fournies via ce site, dans le cadre notamment d'une demande de rendez-vous ou de renseignements, peuvent-être stocké sur les serveurs du site isabelle-kopp.be.
					</p>

					<p>
						Ces données sont utilisées exclusivement pour répondre à votre demande ou pour réaliser des statistiques sur ce types de demandes. Elles ne seront pas transmises ni vendues à des tiers conformément à la Loi relative à la protection de la vie privée du 8/12/1992.
					</p>
					<p>
						La Loi relative à la protection de la vie privée du 8/12/1992, modifiée par la loi du 11/12/1998, vous donne par ailleurs un droit d'accès et de rectification de vos données.
					</p>

					<h3>Cookies</h3>
					<p>Ce site n'utilise pas de cookies.</p>
				</React.Fragment>
			)}
		>
			<title>Mentions légales — Isabelle Kopp</title>
		</Page>
	);
};

export default PageContent;
